import formatAddress from './format-address';

const buildGroupedListingsDropdownOptions = (
  properties,
  specialOptions = [],
) => {
  const portfolioGroups = {};
  const noPortfolioProperties = [];

  properties.forEach((property) => {
    if (property.portfolio?.name) {
      if (!portfolioGroups[property.portfolio.name]) {
        portfolioGroups[property.portfolio.name] = [];
      }
      portfolioGroups[property.portfolio.name].push({
        value: property.id,
        label: property.nickname || formatAddress({ short: true, ...property }),
      });
    } else {
      noPortfolioProperties.push({
        value: property.id,
        label: property.nickname || formatAddress({ short: true, ...property }),
      });
    }
  });

  const options = [...specialOptions, ...noPortfolioProperties];

  Object.keys(portfolioGroups)
    .sort()
    .forEach((portfolioName) => {
      options.push({
        groupLabel: portfolioName,
        options: portfolioGroups[portfolioName],
      });
    });

  return options;
};

export default buildGroupedListingsDropdownOptions;
