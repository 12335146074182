import React, { useMemo, useRef } from 'react';
import { Icon } from '@tt/ui';
import cx from 'classnames';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';

import styles from './CustomSelect.module.scss';

const isGroup = (item) => item?.options && Array.isArray(item.options);

const optionTypes = {
  option: 'option',
  groupLabel: 'group-label',
};

const flattenOptions = (options) => {
  const flat = [];

  options.forEach((entry) => {
    if (isGroup(entry)) {
      flat.push({ type: optionTypes.groupLabel, label: entry.groupLabel });
      entry.options.forEach((opt) =>
        flat.push({ type: optionTypes.option, isGrouped: true, ...opt }),
      );
    } else {
      flat.push({ type: optionTypes.option, isGrouped: false, ...entry });
    }
  });

  return flat;
};

const CustomSelect = ({
  id,
  disabled,
  options,
  input,
  label,
  labelProps,
  className,
  renderOption,
  renderSelectedOption,
  meta,
  dataQa,
  placeholder,
  highlightedClassName,
  selectedClassName,
  optionClassName,
}) => {
  const error = getFieldError(meta);
  const buttonRef = useRef(null);

  const flatItems = useMemo(() => flattenOptions(options), [options]);
  const selectableItems = flatItems.filter(
    (item) => item.type === optionTypes.option,
  );
  const selectedOption = selectableItems.find(
    (opt) => opt.value === input.value,
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    items: selectableItems,
    selectedItem: selectedOption,
    onSelectedItemChange: ({ selectedItem }) => {
      input.onChange(selectedItem?.value);
    },
  });

  const handleMenuKeyDown = (event) => {
    if (event.key === 'Tab' && isOpen) {
      event.preventDefault();
      buttonRef.current?.focus();
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div className={styles.selectWrapper}>
        <button
          {...getToggleButtonProps({ ref: buttonRef })}
          type="button"
          className={cx(styles.trigger, {
            [styles.invalid]: !!error,
            [styles.disabled]: disabled,
          })}
          data-qa={dataQa}
          disabled={disabled}
        >
          {!selectedOption && (
            <span className={styles.placeholder}>{placeholder || ''}</span>
          )}
          {selectedOption &&
            (renderSelectedOption
              ? renderSelectedOption({ option: selectedOption })
              : renderOption({
                  option: selectedOption,
                  isHighlighted: false,
                  isSelected: true,
                }))}
          <Icon name="arrow-down" className={styles.caret} />
        </button>
        <ul
          {...getMenuProps({ onKeyDown: handleMenuKeyDown })}
          id={id}
          className={cx(styles.menu, { [styles.open]: isOpen })}
        >
          {isOpen &&
            flatItems.map((item) => {
              if (item.type === optionTypes.groupLabel) {
                return (
                  <li key={`group-${item.label}`} className={styles.groupLabel}>
                    {item.label}
                  </li>
                );
              }

              const isHighlighted =
                highlightedIndex === selectableItems.indexOf(item);
              const isSelected = selectedOption?.value === item.value;

              return (
                <li
                  key={item.value}
                  {...getItemProps({
                    item,
                    index: selectableItems.indexOf(item),
                    className: cx(styles.option, optionClassName, {
                      [styles.highlighted]: isHighlighted,
                      [highlightedClassName]:
                        highlightedClassName && isHighlighted,
                      [styles.selected]: isSelected,
                      [selectedClassName]: selectedClassName && isSelected,
                      [styles.groupedOption]: item.isGrouped,
                    }),
                  })}
                >
                  {renderOption({
                    option: item,
                    isHighlighted,
                    isSelected,
                  })}
                </li>
              );
            })}
        </ul>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      }),
      PropTypes.shape({
        groupLabel: PropTypes.string,
        options: PropTypes.array,
      }),
    ]),
  ).isRequired,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.object,
  className: PropTypes.string,
  renderOption: PropTypes.func.isRequired,
  renderSelectedOption: PropTypes.func,
  meta: PropTypes.object,
  dataQa: PropTypes.string,
  placeholder: PropTypes.string,
  highlightedClassName: PropTypes.string,
  selectedClassName: PropTypes.string,
  optionClassName: PropTypes.string,
};

export default CustomSelect;
