import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Logo = ({ width = 135, height = 40, color = '', ...rest }) => (
  <svg width={width} height={height} {...rest} viewBox="0 0 135 40">
    <path
      d="M21.118 36.422c0-.181.045-.454.09-.68l1.538-5.775h3.19l.633-2.356h-3.19l.884-3.285h-2.738l-.882 3.285h-1.516l-.633 2.356h1.515l-1.605 6.07a4.857 4.857 0 00-.204 1.381c0 1.427 1.062 2.356 2.985 2.356.838 0 1.584-.136 2.24-.43l.634-2.379c-.589.25-1.2.386-1.742.386-.792 0-1.199-.318-1.199-.929M35.324 27.61l-1.81 6.728c-.475 1.767-1.742 2.99-3.28 2.99-1.313 0-2.06-.68-2.06-1.88 0-.34.069-.838.16-1.2l1.786-6.637h-2.735l-1.742 6.523c-.181.702-.363 1.473-.363 2.107 0 2.197 1.426 3.579 3.641 3.579 1.516 0 2.67-.838 3.62-1.79l-.408 1.54h2.738l3.19-11.96h-2.737zM45.931 30.283l.77-2.899c-2.126-.136-3.687 1.02-4.998 2.923l.7-2.696h-2.736l-3.19 11.96h2.738l1.198-4.44c.882-3.307 2.963-4.848 5.361-4.848h.157z"
      fill={color || colors.secondary}
    />
    <path
      d="M51.39 37.486c-1.788 0-3.01-1.29-3.01-3.035 0-2.537 2.104-4.734 4.298-4.734 1.696 0 2.827 1.132 2.827 2.967 0 2.65-1.877 4.802-4.116 4.802m2.307-10.125c-1.56 0-2.737.748-3.822 1.835l1.65-6.16h-2.737l-4.41 16.535h2.736l.566-2.13c.724 1.337 1.923 2.379 4.026 2.379 3.438 0 6.65-3.104 6.65-7.339 0-3.172-1.99-5.12-4.66-5.12"
      fill={color || '#0069AA'}
    />
    <path
      d="M64.74 37.464c-1.81 0-3.053-1.155-3.053-3.171 0-2.13 1.742-4.553 4.093-4.553 1.81 0 3.054 1.155 3.054 3.171 0 2.175-1.786 4.553-4.094 4.553m1.199-10.103c-3.846 0-6.967 3.375-6.967 7.023 0 3.148 2.194 5.458 5.61 5.458 3.8 0 6.967-3.353 6.967-6.998 0-3.172-2.195-5.483-5.61-5.483"
      fill={color || '#2370A3'}
    />
    <path
      d="M75.778 35.833v-5.867h3.214v-2.355h-3.214v-3.285h-2.735v3.285h-1.516v2.355h1.516v6.297c0 2.628 1.424 3.511 3.437 3.511 1.018 0 1.787-.226 2.466-.611V36.92a3.675 3.675 0 01-1.696.407c-.929 0-1.472-.43-1.472-1.495"
      fill={color || '#231F20'}
    />
    <path
      d="M81.66 32.752c.249-1.857 1.424-3.148 3.098-3.148 1.81 0 2.85 1.382 3.031 3.148h-6.13zm3.12-5.39c-3.415 0-5.834 2.808-5.834 6.251 0 3.715 2.668 6.229 6.13 6.229 2.193 0 3.754-.883 4.885-2.243l-1.606-1.426c-.95.929-1.9 1.426-3.235 1.426-1.764 0-3.121-1.087-3.437-3.035h8.776c.022-.271.045-.498.045-.747 0-3.465-1.922-6.456-5.723-6.456zM97.923 27.361c-1.855 0-2.963.975-3.732 2.107v-1.857h-2.737v11.96h2.737v-6.75c0-1.858 1.108-2.968 2.692-2.968 1.628 0 2.555 1.064 2.555 2.922v6.796h2.738V31.96c0-2.786-1.56-4.598-4.253-4.598M111.043 35.244c0 1.495-1.357 2.514-3.166 2.514-1.29 0-2.307-.634-2.307-1.766v-.046c0-1.223 1.017-1.925 2.736-1.925 1.063 0 2.036.204 2.737.475v.748zm-2.6-7.814c-1.946 0-3.236.407-4.547.996l.746 2.198c1.085-.454 2.081-.748 3.415-.748 1.9 0 2.94.906 2.94 2.56v.293c-.927-.294-1.854-.498-3.3-.498-2.784 0-4.842 1.27-4.842 3.852v.044c0 2.401 1.99 3.693 4.253 3.693 1.808 0 3.053-.748 3.867-1.722v1.473h2.715v-7.09c0-3.194-1.72-5.051-5.248-5.051zM121.607 27.361c-1.855 0-2.964.975-3.733 2.107v-1.857h-2.737v11.96h2.737v-6.75c0-1.858 1.109-2.968 2.692-2.968 1.629 0 2.556 1.064 2.556 2.922v6.796h2.737V31.96c0-2.786-1.56-4.598-4.252-4.598M133.867 29.967V27.61h-3.213v-3.285h-2.736v3.285h-1.516v2.356h1.516v6.296c0 2.628 1.424 3.511 3.437 3.511 1.019 0 1.788-.226 2.466-.611v-2.242a3.673 3.673 0 01-1.696.407c-.928 0-1.471-.43-1.471-1.495v-5.866h3.213z"
      fill={color || '#231F20'}
    />
    <path
      fill={color || colors.secondary}
      d="M16.79 29.868l.374-2.114H9.306l-.375 2.125zM15.31 37.534l.373-2.115H7.825l-.375 2.126zM15.807 33.605l.372-2.116H.374L0 33.616z"
    />
    <path
      d="M81.1 4.545c0-.222.18-.402.401-.402h5.113c.222 0 .403.18.403.402V8.06L81.1 11.636V4.545zm53.11 12.974L105.414.775c-1.812-1.054-4.635-1.03-6.428.054L88.736 7.022V4.545a2.125 2.125 0 00-2.122-2.125h-5.113c-1.17 0-2.121.953-2.121 2.125v8.13l-8.032 4.854a1.092 1.092 0 001.127 1.869l27.636-16.7c1.115-.673 3.081-.69 4.207-.035l28.798 16.744a1.09 1.09 0 001.49-.396 1.093 1.093 0 00-.395-1.492z"
      fill={color || '#231F20'}
    />
  </svg>
);

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Logo);
