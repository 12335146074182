import React from 'react';
import { Icon as TTIcon } from '@tt/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './HelpfulInfoListItem.module.scss';

const HelpfulInfoListItem = ({
  children,
  className,
  icon: Icon,
  iconProps: { className: iconClassName, ...otherIconProps } = {},
}) => {
  return (
    <li
      className={cx(
        styles.helpfulListItem,
        { [styles.withIcon]: Icon },
        className,
      )}
    >
      {typeof Icon === 'string' ? (
        <span>
          <TTIcon name={Icon} {...otherIconProps} />
        </span>
      ) : (
        Icon && (
          <span>
            <Icon
              className={cx(styles.icon, iconClassName)}
              {...otherIconProps}
            />
          </span>
        )
      )}
      {children}
    </li>
  );
};

HelpfulInfoListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconProps: PropTypes.object,
};

export default HelpfulInfoListItem;
