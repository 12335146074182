import FileType from 'file-type/browser';

import { getCurrentToken } from '../core/auth/authService';
import { TTfetch } from '../core/TTfetch';

import { getConfig } from './configService';

const types = {
  'listing': {
    url: 'listingphotourl',
    keyName: 'filename',
  },
  'attachments': {
    url: 'attachmentsurl',
    keyName: 'attachmentName',
  },
  'stripe-identities': {
    url: 'stripe-identities-documents',
    keyName: 'filename',
  },
  'listing-cover-photo': {
    url: 'listingcoverphotourl',
    keyName: 'filename',
  },
  'charges-attachments': {
    url: 'charges-attachments',
    keyName: 'filename',
  },
  'home-guide-attachments': {
    url: 'home-guide-attachments',
    keyName: 'filename',
  },
  'home-guide-welcome-image': {
    url: 'home-guide-welcome-image',
    keyName: 'filename',
  },
};

export const ATTACHMENT_TYPE = {
  ID: 'ID',
  PROOF_OF_INCOME: 'PROOF_OF_INCOME',
  OTHER: 'OTHER',
};

function getIdPhotoURL(fileTag) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    authorization: token,
  };

  return TTfetch(`${API_URL}/uploader/renter/profilepic/${fileTag}`, {
    method: 'GET',
    headers,
  })
    .then((res) => {
      if (res.status === 200) {
        return res.blob().then((blob) => URL.createObjectURL(blob));
      }
      return null;
    })
    .catch((error) => {
      console.error(error);
    });
}

function getAttachmentsURL(filename) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };

  return TTfetch(`${API_URL}/uploader/attachmentsurl/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      attachmentName: filename,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getRenterProfilePic(file) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    authorization: token,
  };
  const formData = new FormData();
  formData.append('file', file);

  return TTfetch(`${API_URL}/uploader/renter/profilepic`, {
    method: 'POST',
    headers,
    body: formData,
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.json());
}

function getImageURL({ fileName, type, imageType, otherParams = {} }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  const { url, keyName } = types[imageType];
  return TTfetch(`${API_URL}/uploader/${url}/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      [keyName]: fileName,
      type,
      ...otherParams,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getUploadUrl({ fileName, type, resourceType, ...otherParams }) {
  return getImageURL({ fileName, type, imageType: resourceType, otherParams });
}

function getDocumentURL({ fileName, listingId }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/listingdocuments/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileName,
      listingId,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getLeaseDocumentURL({ fileName, leaseId }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/lease-documents/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileName,
      leaseId,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

async function getUploadedDocumentURL({ fileName }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  const response = await TTfetch(`${API_URL}/uploader/uploaded_documents`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileName,
    }),
  });
  return response.json();
}
async function getDraftDocumentURL({ fileName }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  const response = await TTfetch(`${API_URL}/uploader/draft_documents`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileName,
    }),
  });
  return response.text();
}

function getExpenseDocumentUrl(filename) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/expense_documents`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      filename,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getListingPhotoUrl(filename) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };

  return TTfetch(`${API_URL}/uploader/listingphotourl/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      filename,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getListingPhotoUrlToEdit(photo_id) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };

  return TTfetch(`${API_URL}/uploader/listingphotourl/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      photo_id,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getCustomLogoUrl() {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };

  return TTfetch(`${API_URL}/uploader/customlogourl/`, {
    method: 'POST',
    headers,
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

function getInsuranceProofUrl(filename, renterId, listingId) {
  const { API_URL, IS_OWNER } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/insuranceproofurl/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      filename,
      ...(renterId ? { renterId } : {}),
      ...(listingId ? { listingId } : {}),
      accountType: IS_OWNER ? 1 : 2,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

async function uploadFileToS3(s3Params, file) {
  const formData = new FormData();
  formData.append('acl', s3Params.params.acl);
  formData.append('key', s3Params.params.key);
  formData.append('policy', s3Params.params.policy);
  formData.append(
    'success_action_status',
    s3Params.params.success_action_status,
  );
  formData.append('x-amz-algorithm', s3Params.params['x-amz-algorithm']);
  formData.append('x-amz-credential', s3Params.params['x-amz-credential']);
  formData.append('x-amz-date', s3Params.params['x-amz-date']);
  formData.append('x-amz-signature', s3Params.params['x-amz-signature']);
  const fileType = await FileType.fromBlob(file);
  if (fileType && fileType.mime) {
    formData.append('Content-Type', fileType.mime);
  }
  formData.append('file', file);

  let apiResponse;
  try {
    apiResponse = await TTfetch(s3Params.endpoint_url, {
      method: 'POST',
      body: formData,
    });
    if (apiResponse.ok) {
      return apiResponse;
    }
  } catch (e) {
    if (window.atatus) {
      window.atatus.notify(new Error('Failed to upload a file to S3'), {
        extra: {
          s3Params,
          fileType,
          response: {
            rawError: e,
            status: apiResponse?.status,
            statusText: apiResponse?.statusText,
          },
        },
      });
    }

    throw e;
  }
}

function getMaintenanceRequestAttachmentUploadURL({ filename, listing_id }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/maintenance-request-attachment`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      filename,
      listing_id,
    }),
  }).then((response) => response.json());
}

function getVerificationDocumentURL({ type, listing_id, file_type }) {
  const { API_URL } = getConfig();
  const token = getCurrentToken();
  const headers = {
    'authorization': token,
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/verification-documents`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      type,
      listing_id,
      file_type,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.text());
}

const uploadAttachmentsToS3 = async (files = []) => {
  const filesUrls = await Promise.all(
    files.map(async ({ name, file, type }) => {
      const response = await getImageURL({
        fileName: name,
        imageType: 'attachments',
        type,
      });

      return {
        name: name,
        file,
        s3Params: JSON.parse(response),
        type,
      };
    }),
  );

  return await Promise.all(
    filesUrls.map(async ({ name, file, s3Params, type }) => {
      const urlObject =
        s3Params?.url || (s3Params.urls ? s3Params.urls[0] : null);

      if (!urlObject)
        return console.warn(`s3Params is not provided for: ${name}`);

      try {
        await uploadFileToS3(urlObject, file);

        return {
          id: s3Params.id,
          file_url: `${urlObject.endpoint_url}/${encodeURIComponent(
            urlObject.params.key,
          )}`,
          content: name,
          type,
          rotation: 0,
        };
      } catch (e) {
        console.error('Bad request', e);
      }
    }),
  );
};

function getNonTTLeaseAgreementUploadUrl({ fileName, state }) {
  const { API_URL } = getConfig();
  const headers = {
    'Content-Type': 'application/json',
  };
  return TTfetch(`${API_URL}/uploader/non-tt-lease-agreements/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileName,
      state,
    }),
  })
    .then((apiResponse) => apiResponse)
    .then((response) => response.json());
}

export {
  getImageURL,
  getIdPhotoURL,
  getAttachmentsURL,
  getListingPhotoUrl,
  getListingPhotoUrlToEdit,
  getCustomLogoUrl,
  getRenterProfilePic,
  getInsuranceProofUrl,
  getDocumentURL,
  getLeaseDocumentURL,
  getMaintenanceRequestAttachmentUploadURL,
  uploadFileToS3,
  getVerificationDocumentURL,
  getExpenseDocumentUrl,
  uploadAttachmentsToS3,
  getUploadUrl,
  getNonTTLeaseAgreementUploadUrl,
  getUploadedDocumentURL,
  getDraftDocumentURL,
};
