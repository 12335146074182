import React, { useCallback, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import MAINTENANCE_CATEGORIES from '../../../constants/maintenanceCategories';
import getInitialMUPFieldDropdownValues from '../../../helpers/getInitialMUPFieldDropdownValues';
import buildLeasesDropdownOptions from '../../../pages/properties/maintenance/utils/buildLeasesDropdownOptions';
import {
  getMaintenanceRequestAttachmentUploadURL,
  uploadFileToS3,
} from '../../../services/cloudFilesService';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import validPhone from '../../../validators/validPhone';
import Button from '../../Button';
import Card from '../../Card';
import FinalFormError from '../../FinalFormError';
import IconTooltip from '../../IconTooltip';
import Input from '../../Input';
import Label from '../../Label';
import PhoneField from '../../PhoneField';
import PhotoUploadPreview from '../../PhotoUploadPreview';
import PropertyUnitRoomDropdowns from '../../PropertyUnitRoomDropdowns';
import RadioGroup from '../../RadioGroup';
import SelectField from '../../SelectField';
import UploadDropZone from '../../UploadDropZone';

import styles from './MaintenanceForm.module.scss';

const formTitle = (isRenter, isEdit) => {
  if (isRenter) {
    return 'Request Maintenance';
  }
  if (isEdit) {
    return 'Edit Maintenance Request';
  }
  return 'Create Maintenance Request';
};

const formDescription = (isRenter, isEdit) => {
  if (isRenter) {
    return 'Tell the landlord about the issue you’re having at the property.';
  }
  if (isEdit) {
    return '';
  }
  return 'Create a request yourself to stay organized and keep accurate records.';
};

const formActionLabel = (isEdit, isRenter) => {
  if (isEdit) {
    return 'Save Changes';
  }
  if (isRenter) {
    return 'Submit Request';
  }
  return 'Create Request';
};

const MaintenanceForm = ({
  className,
  isRenter,
  isEdit,
  onPropertyChange,
  initialValues = {},
  onSubmit,
  leases,
  properties,
  listing,
  isLoading,
  maintenancePlusActive,
  mainApproverPhone,
  renterPhone,
  renterId,
}) => {
  const [uploading, setUploading] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(
    isEdit ? listing.id : initialValues?.property_id || null,
  );

  const isUniversal = !!properties;

  const thisLeases =
    !isRenter &&
    leases.filter((l) => {
      return l.status !== 'PAST' && l.listing.id === selectedListingId;
    });

  const getRenterOptions = (leaseId) => {
    const noTenantOption = {
      value: 'NONE',
      label: 'No Tenant',
    };

    if (!leaseId) return [noTenantOption];
    const lease = thisLeases.find((l) => l.id === leaseId);
    const renters = lease?.renters || [];
    return [
      noTenantOption,
      ...renters.map((renter) => ({
        value: renter.id,
        label: `${renter.first_name} ${renter.last_name}`,
      })),
    ];
  };

  const getRenterFromLease = (leaseId, renterId) => {
    if (!leaseId || !renterId) return null;
    const lease = thisLeases.find((l) => l.id === leaseId);
    const renter = lease?.renters.find((r) => r.id === renterId);
    return renter;
  };

  const uploadFiles = useCallback(
    async (needUpload) => {
      setUploading(true);
      const files = await Promise.all(
        needUpload.map(async (file) => {
          const urlResponse = await getMaintenanceRequestAttachmentUploadURL({
            filename: file.path,
            listing_id: listing?.id || selectedListingId,
          });
          const s3Params = get(urlResponse, 'attachments[0].s3Params');
          await uploadFileToS3(s3Params.url, file);
          return s3Params;
        }),
      );
      setUploading(false);
      return files;
    },
    [setUploading, selectedListingId],
  );

  let initialRenterId = isEdit ? initialValues?.renter_id : null;

  let initialContactPhoneNumber = isEdit
    ? initialValues?.contact_phone_number
    : '';

  if (isRenter && !isEdit) {
    // Renter is creating a MR, we fill in their phone number
    initialContactPhoneNumber = renterPhone || '';
    initialRenterId = renterId;
  } else if (!isRenter && isEdit && !initialRenterId) {
    // Owner is editing a MR, if there's no renter_id, we fill in with the LL's phone number
    initialContactPhoneNumber = mainApproverPhone || '';
    initialRenterId = 'NONE';
  }

  // TODO: selected parent listing can't change, but we need to change unit or room, right now everything is disabled

  return (
    <Card className={cx(styles.card, className)}>
      <h2 className={cx(styles.title)}>{formTitle(isRenter, isEdit)}</h2>
      <p className={cx(styles.subTitle)}>{formDescription(isRenter, isEdit)}</p>
      <Form
        onSubmit={({ renter_id, contact_phone_number, ...rest }) => {
          if (renter_id === 'NONE' || !renter_id) {
            return onSubmit(rest);
          }

          return onSubmit({
            ...rest,
            renter_id,
            contact_phone_number,
          });
        }}
        initialValues={{
          ...initialValues,
          ...(listing ? getInitialMUPFieldDropdownValues(listing) : {}),
          ...(initialContactPhoneNumber
            ? { contact_phone_number: initialContactPhoneNumber }
            : {}),
          ...(initialRenterId ? { renter_id: initialRenterId } : {}),
        }}
        subscription={{
          submitting: true,
          values: true,
        }}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
      >
        {({ handleSubmit, submitting, form }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className={cx(styles.container, className)}
            >
              <FinalFormError />
              <OnChange name="lease_id">
                {() => {
                  form.change('renter_id', null);
                }}
              </OnChange>
              {!isRenter && (properties || listing) && (
                <PropertyUnitRoomDropdowns
                  id="property"
                  properties={
                    isUniversal
                      ? properties
                      : [listing?.root_listing || listing]
                  }
                  lockPreSelectedFields={!isUniversal}
                  onListingChange={(listingId) => {
                    setSelectedListingId(listingId);
                    onPropertyChange && onPropertyChange(listingId);
                  }}
                  allowParentSelection
                />
              )}
              {(!isUniversal || selectedListingId) && (
                <>
                  {!isRenter && (
                    <>
                      {thisLeases && thisLeases.length ? (
                        <Field
                          label="Lease"
                          component={SelectField}
                          name="lease_id"
                          id="lease"
                          className={cx(styles.lease, styles.fieldWithMargin, {
                            [styles.disabled]: thisLeases.length === 0,
                          })}
                          options={buildLeasesDropdownOptions(thisLeases)}
                          empty={true}
                          labelProps={{ hint: '(Optional)' }}
                          disabled={thisLeases.length === 0}
                        />
                      ) : null}

                      {maintenancePlusActive && (
                        <FormSpy
                          subscription={{
                            values: true,
                          }}
                        >
                          {({ values }) => {
                            const renterOptions = getRenterOptions(
                              values.lease_id,
                            );

                            if (renterOptions.length === 0) return null;

                            const selectedRenter =
                              values.renter_id !== 'NONE'
                                ? getRenterFromLease(
                                    values.lease_id,
                                    values.renter_id,
                                  )
                                : null;

                            return (
                              <>
                                <Field
                                  label={
                                    <>
                                      Tenant Contact{' '}
                                      <IconTooltip
                                        className={styles.iconTooltip}
                                        tip={
                                          <span>
                                            Pick the right contact. Choose 'No
                                            Tenant' if it's you. To see your
                                            tenants, select a Lease above.
                                          </span>
                                        }
                                      />
                                    </>
                                  }
                                  labelProps={{
                                    className: styles.labelWithTooltip,
                                  }}
                                  component={SelectField}
                                  name="renter_id"
                                  id="renter_id"
                                  className={styles.fieldWithMargin}
                                  options={renterOptions}
                                  onChange={(e) => {
                                    const renterId = e.target.value;

                                    form.mutators.setValue(
                                      'renter_id',
                                      renterId,
                                    );

                                    if (renterId === 'NONE') {
                                      form.mutators.setValue(
                                        'contact_phone_number',
                                        mainApproverPhone || '',
                                      );
                                    } else {
                                      const renter = getRenterFromLease(
                                        values.lease_id,
                                        renterId,
                                      );

                                      form.mutators.setValue(
                                        'contact_phone_number',
                                        renter?.telephone || '',
                                      );
                                    }
                                  }}
                                  validate={fieldRequired}
                                  empty
                                />

                                {values.renter_id && (
                                  <Field
                                    label={
                                      <>
                                        Contact Phone Number{' '}
                                        <IconTooltip
                                          className={styles.iconTooltip}
                                          tip={
                                            <span>
                                              Only tenants can update their
                                              phone number. You can update yours
                                              in Settings.
                                            </span>
                                          }
                                        />
                                      </>
                                    }
                                    labelProps={{
                                      className: styles.labelWithTooltip,
                                    }}
                                    component={PhoneField}
                                    name="contact_phone_number"
                                    id="contact_phone_number"
                                    className={styles.fieldWithMargin}
                                    validate={composeValidators(
                                      fieldRequired,
                                      validPhone,
                                    )}
                                    disabled={
                                      values.renter_id === 'NONE' ||
                                      !!selectedRenter?.telephone
                                    }
                                  />
                                )}
                              </>
                            );
                          }}
                        </FormSpy>
                      )}
                    </>
                  )}
                  <Field
                    label="Issue Category"
                    component={SelectField}
                    name="category"
                    id="category"
                    className={cx(styles.lease, styles.fieldWithMargin)}
                    options={MAINTENANCE_CATEGORIES}
                    empty
                    validate={fieldRequired}
                  />
                  <Field
                    label="Issue Title"
                    component={Input}
                    name="title"
                    id="issue_title"
                    className={cx(styles.issueTitle, styles.fieldWithMargin)}
                    maxLength={50}
                    help={(val = '') => `${val.length} / 50 characters used`}
                    hint={
                      <i className={styles.issueDescription}>
                        e.g. “Leaky Kitchen Faucet”
                      </i>
                    }
                    validate={fieldRequired}
                  />
                  <Field
                    label="Description"
                    component={Input}
                    name="description"
                    id="description"
                    className={styles.description}
                    inputProps={{
                      as: 'textarea',
                    }}
                    labelProps={{
                      sublabel:
                        'Add as much detail as possible including the specific location.',
                    }}
                    validate={fieldRequired}
                  />
                  <Field
                    label="Preferred Time to Enter"
                    component={RadioGroup}
                    name="preferred_time"
                    id="preferred_time"
                    className={cx(
                      styles.fieldWithMargin,
                      styles.labelBottomMargin,
                    )}
                    options={[
                      {
                        value: 'ANYTIME',
                        label: 'Anytime',
                      },
                      {
                        value: 'COORDINATE',
                        label: 'Coordinate a Time First',
                      },
                    ]}
                    validate={fieldRequired}
                  />
                  {isRenter && (
                    <Field
                      label="Phone Number"
                      component={PhoneField}
                      name="contact_phone_number"
                      id="contact_phone_number"
                      className={styles.fieldWithMargin}
                      validate={composeValidators(fieldRequired, validPhone)}
                      hint={
                        <i className={styles.issueDescription}>
                          This updates your account phone number.
                        </i>
                      }
                    />
                  )}
                  <div className={styles.media}>
                    <Label hint="(Optional)" htmlFor="attachments">
                      Photos
                    </Label>
                    <Field component={PhotoUploadPreview} name="attachments" />
                    <Field
                      component={UploadDropZone}
                      uploadFiles={uploadFiles}
                      accept="image/*"
                      name="attachments"
                      id="attachments"
                    />
                  </div>
                  <Button
                    className={styles.submit}
                    type="submit"
                    loading={submitting}
                    id="request_submit"
                    disabled={uploading || isLoading}
                  >
                    {formActionLabel(isEdit, isRenter)}
                  </Button>
                </>
              )}
            </form>
          );
        }}
      </Form>
    </Card>
  );
};

MaintenanceForm.propTypes = {
  className: PropTypes.string,
  isRenter: PropTypes.bool,
  isEdit: PropTypes.bool,
  onPropertyChange: PropTypes.func,
  leases: PropTypes.array,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  properties: PropTypes.array,
  listing: PropTypes.object,
  isLoading: PropTypes.bool,
  maintenancePlusActive: PropTypes.bool,
  mainApproverPhone: PropTypes.string,
  renterPhone: PropTypes.string,
  renterId: PropTypes.string,
};

export default MaintenanceForm;
